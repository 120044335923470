<template>
  <v-card class="mb-6">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    subtitle: {
      type: String,
      required: true,
      default: null,
    },
  },
}
</script>
