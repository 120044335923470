<template>
  <v-pagination
    :value="page"
    :length="count"
    circle
    :total-visible="7"
    class="py-2"
    @input="p => $emit('update:page', p)"
  ></v-pagination>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      required: false,
      default: 1,
    },
    count: {
      type: Number,
      required: false,
      default: 1,
    },
  },
}
</script>
