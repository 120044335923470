<template>
  <div>
    <!-- <loading v-if="$apollo.loading || loading" /> -->
    <v-simple-table v-if="!loading">
      <template v-slot:default>
        <thead class="position-relative">
          <tr>
            <th
              class="text-center text-uppercase"
              @click="changeOrder('LOCATION')"
            >
              Location
              <sorting-arrows :direction="column === 'LOCATION' ? order : null" />
            </th>
            <th
              class="text-center text-uppercase"
              @click="changeOrder('GENERATED_AT')"
            >
              Date
              <sorting-arrows :direction="column === 'GENERATED_AT' ? order : null" />
            </th>
            <th
              v-if="type === 0"
              class="text-center text-uppercase"
              @click="changeOrder('CAR')"
            >
              Cars
              <sorting-arrows :direction="column === 'CAR' ? order : null" />
            </th>
            <th
              v-if="type === 0"
              class="text-center text-uppercase"
              @click="changeOrder('BUS')"
            >
              Buses
              <sorting-arrows :direction="column === 'BUS' ? order : null" />
            </th>
            <th
              v-if="type === 0"
              class="text-center text-uppercase"
              @click="changeOrder('TRUCK')"
            >
              Trucks
              <sorting-arrows :direction="column === 'TRUCK' ? order : null" />
            </th>
            <th
              v-if="type === 0"
              class="text-center text-uppercase"
              @click="changeOrder('MOTORBIKE')"
            >
              Motorbikes
              <sorting-arrows :direction="column === 'MOTORBIKE' ? order : null" />
            </th>
            <th
              v-if="type === 1"
              class="text-center text-uppercase"
              @click="changeOrder('BOAT')"
            >
              Boats
              <sorting-arrows :direction="column === 'BOAT' ? order : null" />
            </th>
          </tr>

          <v-progress-linear
            bottom
            absolute
            indeterminate
            :active="$apollo.loading || $apollo.queries.votmax_data.loading || loading"
          ></v-progress-linear>
        </thead>

        <tbody>
          <tr v-if="votmaxData && votmaxData.length === 0">
            <td
              colspan="7"
              class="text-center"
            >
              <h3>
                <v-icon size="20">
                  {{ icons.mdiCancel }}
                </v-icon>
                No items
              </h3>
            </td>
          </tr>
          <tr
            v-for="item in votmaxData"
            v-else
            :key="item.id"
          >
            <td class="text-center">
              {{ item.location }}
            </td>
            <td class="text-center">
              {{ item.last_update }}
            </td>
            <td
              v-if="type === 0"
              class="text-center"
            >
              {{ item.car }}
            </td>
            <td
              v-if="type === 0"
              class="text-center"
            >
              {{ item.bus }}
            </td>
            <td
              v-if="type === 0"
              class="text-center"
            >
              {{ item.truck }}
            </td>
            <td
              v-if="type === 0"
              class="text-center"
            >
              {{ item.motorbike }}
            </td>
            <td
              v-if="type === 1"
              class="text-center"
            >
              {{ item.boat }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <pagination
      v-if="!loading && votmaxData && votmaxData.length > 0"
      :count="countPages"
      :page.sync="currentPage"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiPencilOutline,
  mdiTrashCanOutline,
  mdiViewListOutline,
  mdiCancel,
  mdiFileClockOutline,
  mdiAlert,
} from '@mdi/js'
import Pagination from '@/views/components/pagination/Pagination.vue'
import gql from 'graphql-tag'
// import Loading from '@/views/components/Loading.vue'
import SortingArrows from '@/views/components/SortingArrows.vue'

export default {
  name: 'VotmaxTable',
  components: {
    Pagination,
    // Loading,
    SortingArrows,
  },
  props: {
    dates: {
      required: false,
      default: null,
      type: Array,
    },
    location: {
      required: false,
      default: null,
      type: String,
    },
    type: {
      required: false,
      default: null,
      type: Number,
    },
  },
  apollo: {
    votmax_data: {
      query: gql`
        query (
          $location: String
          $dates: [String]
          $first: Int
          $page: Int
          $type: String!
          $orderBy: [QueryVotmaxDataOrderByOrderByClause!]
        ) {
          votmax_data(location: $location, dates: $dates, first: $first, page: $page, type: $type, orderBy: $orderBy) {
            data {
              id
              generated_at
              last_update
              car
              bus
              truck
              motorbike
              location
              created_at
              type
              boat
            }
            paginatorInfo {
              currentPage
              lastPage
            }
          }
        }
      `,
      debounce: 150,
      skip() {
        return this.dates?.length === 1
      },
      variables() {
        return {
          location: this.location ?? undefined,
          orderBy: [
            {
              column: this.column ?? 'GENERATED_AT',
              order: this.order ?? 'ASC',
            },
          ],
          page: this.currentPage,
          first: 10,
          dates: this.dates ?? undefined,
          type: this.type === 0 ? 'land' : 'maritime',
        }
      },
      result(result) {
        this.votmaxData = result.data.votmax_data.data
        this.countPages = result.data.votmax_data.paginatorInfo.lastPage
        this.currentPage = result.data.votmax_data.paginatorInfo.currentPage
        this.loading = false
      },
      errorPolicy: 'all',
      //   fetchPolicy: 'network-only',
    },
  },

  setup(props) {
    /* eslint-disable no-unused-vars */
    const currentPage = ref(1)
    const countPages = ref(1)
    /* eslint-enable no-unused-vars */
    const searchProp = ref(props.search)
    const column = ref('GENERATED_AT')
    const order = ref('ASC')
    const votmaxData = ref([])
    const loading = ref(false)

    return {
      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiViewListOutline,
        mdiCancel,
        mdiFileClockOutline,
        mdiAlert,
      },
      searchProp,
      votmaxData,
      column,
      order,
      loading,

      countPages,
      currentPage,
    }
  },

  watch: {
    type(newVal, oldVal) {
      if (newVal !== oldVal) this.loading = true
    },
  },

  methods: {
    changeOrder(column) {
      this.column = column
      this.order = this.order === 'ASC' ? 'DESC' : 'ASC'
    },
  },
}
</script>
