<template>
  <div>
    <v-card class="mb-4 px-4 pb-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="5"
          lg="4"
        >
          <div class="pl-1 mb-1">
            Location
          </div>
          <v-select
            v-model="selectedLocation"
            :items="locations"
            clearable
            placeholder="Select Location"
            hide-details
            solo
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="5"
          lg="4"
        >
          <div class="pl-1 mb-1">
            Date Range
          </div>
          <date-range-picker
            :input-style="{
              minHeight: '48px',
              maxWidth: 'auto',
            }"
            @input="dates = $event"
          />
        </v-col>

        <v-col
          class="d-flex justify-end"
          align-self="end"
        >
          <v-btn
            class=""
            color="primary"
            @click="isDialogVisible = true"
          >
            export
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mb-4">
      <v-tabs
        v-model="currentTab"
        grow
      >
        <v-tab>Land transport</v-tab>
        <v-tab>Maritime transport</v-tab>
      </v-tabs>
      <chartjs-line-chart
        v-if="votmax_chart_data"
        :chart_data="votmax_chart_data"
        class="mt-6"
      ></chartjs-line-chart>
    </v-card>
    <v-card class="mb-4">
      <votmax-table
        id="votmax-table"
        :location="selectedLocation"
        :type="currentTab"
        :dates="dates"
      />
    </v-card>

    <!-- Export Modal -->
    <v-dialog
      v-if="isDialogVisible"
      v-model="isDialogVisible"
      max-width="300px"
      @click:outside="onCloseModal()"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Select Export</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-radio-group v-model="selectedRadio">
                <v-radio
                  v-for="option in radioOptions"
                  :key="option.id"
                  :label="option.label"
                  :value="option"
                ></v-radio>
              </v-radio-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            small
            outlined
            @click="onCloseModal()"
          >
            Close
          </v-btn>
          <v-btn
            small
            :disabled="!selectedRadio"
            color="success"
            @click="exportData()"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from '@vue/composition-api'
import ChartjsLineChart from '@/views/components/charts/ChartjsLineChart.vue'
import gql from 'graphql-tag'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import ChartDataMixin from '@/mixins/ChartDataMixin'
import Loading from '@/views/components/Loading.vue'
import VotmaxTable from '../components/votmax/VotmaxTable.vue'
import PageTitle from '../components/PageTitle.vue'
import DateRangePicker from '../components/DateRangePicker.vue'

export default {
  components: {
    ChartjsLineChart,
    Loading,
    VotmaxTable,
    PageTitle,
    DateRangePicker,
  },
  mixins: [ChartDataMixin],
  apollo: {
    votmax_data_chart: {
      query: gql`
        query ($location: String, $dates: [String], $type: String!) {
          votmax_data_chart(location: $location, dates: $dates, type: $type) {
            id
            generated_at
            last_update
            car
            bus
            truck
            motorbike
            location
            created_at
            type
            boat
          }
        }
      `,
      skip() {
        return this.dates?.length == 1
      },
      variables() {
        return {
          location: this.selectedLocation ?? undefined,
          dates: this.dates ?? undefined,
          type: this.currentTab === 0 ? 'land' : 'maritime',
        }
      },
      result(result) {
        let votmaxData = result.data.votmax_data_chart
        this.checkDiffDays()

        let labels = votmaxData.map(v => {
          if (this.diffDays > 2 && this.diffDays <= 31) return v.last_update.slice(0, 10)
          if (this.diffDays > 31 && this.diffDays <= 365) return v.last_update.slice(0, 7)

          return v.last_update
        })

        let cars = votmaxData.map(v => v.car)
        let motorbikes = votmaxData.map(v => v.motorbike)
        let trucks = votmaxData.map(v => v.truck)
        let buses = votmaxData.map(v => v.bus)
        let boats = votmaxData.map(v => v.boat)
        let datasets = null

        if (this.currentTab === 0) {
          datasets = {
            cars,
            motorbikes,
            trucks,
            buses,
          }
        }

        if (this.currentTab === 1) {
          datasets = {
            boats,
          }
        }

        let data = {
          labels,
          datasets,
        }
        this.votmax_chart_data = this.getLineChartData(data)
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
    votmax_data_locations: {
      query: gql`
        query ($type: String!) {
          votmax_data_locations(type: $type)
        }
      `,
      variables() {
        return {
          type: this.currentTab === 0 ? 'land' : 'maritime',
        }
      },
      fetchPolicy: 'network-only',
      result(result) {
        this.locations = result.data.votmax_data_locations
      },

      errorPolicy: 'all',
    },
  },
  setup() {
    const votmax_chart_data = ref(null)
    const locations = ref([])
    const selectedLocation = ref(null)
    const currentTab = ref(0)
    const dates = ref([])
    const isDialogVisible = ref(false)
    const selectedRadio = ref(null)
    const radioOptions = [
      {
        id: 0,
        label: 'Chart (.pdf)',
      },
      {
        id: 1,
        label: 'Table (.csv)',
      },
      {
        id: 2,
        label: 'History (.csv)',
      },
    ]
    const diffDays = ref(null)

    return {
      votmax_chart_data,
      locations,
      selectedLocation,
      currentTab,
      dates,
      isDialogVisible,
      selectedRadio,
      radioOptions,
      diffDays,
    }
  },
  methods: {
    exportChart() {
      const doc = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        hotfixes: ['px_scaling'],
      })

      var chart = document.getElementById('line-chart')
      var chart_width = chart.getAttribute('width')
      var chart_height = chart.getAttribute('height')
      var x = 20
      var doc_height = doc.internal.pageSize.getHeight()
      var doc_width = doc.internal.pageSize.getWidth() - 2 * x
      var scale = Math.max(chart_width / doc_width, chart_height / doc_height)

      html2canvas(document.querySelector('#line-chart')).then(canvas => {
        const img = canvas.toDataURL('image/png', 1.0)
        doc.text(20, 40, 'Votmax Data')
        doc.addImage(img, 'PNG', x, 60, chart_width / scale, chart_height / scale)
        doc.save('VotmaxData')
      })

      // this.$toastr.e('Something went wrong')
    },
    exportTable() {
      this.$http
        .get('votmax/export', {
          params: {
            range: this.selectedRadio.id === 2 ? null : this.dates,
            type: this.currentTab === 0 ? 'land' : 'maritime',
            location: this.selectedLocation ?? null,
          },
          responseType: 'blob',
        })
        .then(response => {
          let filename = ''
          const disposition = response.headers['content-disposition']
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(disposition)
            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '')
          }
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = filename
          link.click()
        })
        .catch(error => {
          this.$toastr.e('Something went wrong! ' + error)
        })
    },
    exportData() {
      if (this.selectedRadio.id === 0) {
        this.exportChart()
      }

      if (this.selectedRadio.id === 1 || this.selectedRadio.id === 2) {
        this.exportTable()
      }

      this.onCloseModal()
    },
    onCloseModal() {
      this.isDialogVisible = false
      this.selectedRadio = null
    },
    checkDiffDays() {
      if (this.dates?.length == 2) {
        var a = this.$moment(this.dates[0], 'YYYY-MM-DD')
        var b = this.$moment(this.dates[1], 'YYYY-MM-DD')
        this.diffDays = b.diff(a, 'days') + 1
      }
    },
  },
  watch: {
    currentTab(newVal, oldVal) {
      if (newVal != oldVal) {
        this.selectedLocation = null
      }
    },
  },
}
</script>
