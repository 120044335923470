<template>
    <!-- chart -->
    <v-card-text>
      <chartjs-component-line-chart
        v-if="chart_data"
        :height="400"
        :data="chart_data.data"
        :options="chart_data.options"
        :plugins="plugins"
      />
    </v-card-text>
</template>

<script>
/* eslint-disable */
import ChartjsComponentLineChart from './charts-components/ChartjsComponentLineChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    ChartjsComponentLineChart,
  },
  props: {
    chart_data: {
      required: true,
    },
  },
  setup() {
    const plugins = [
      // to add spacing between legends and chart
      {
        beforeInit(chart) {
          chart.legend.afterFit = function () {
            this.height += 20
          }
        },
      },
    ]

    return {
      plugins,
      chartjsData,
    }
  },
}
</script>
