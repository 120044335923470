/* eslint-disable */
import themeConfig from '@themeConfig'
import chartColors from '@/views/components/charts/chartColors'

const $themeColors = themeConfig.themes.light

export default {
  methods: {
    getLineChartData(data) {
      let chartDatesets = []
      let i = 0

      for (const [key, value] of Object.entries(data.datasets)) {
        i++
        let dataset = {
          data: value,
          label: key.charAt(0).toUpperCase() + key.slice(1),
          borderColor: chartColors[Object.keys(chartColors)[i]],
          lineTension: 0.5,
          pointStyle: 'circle',
          backgroundColor: chartColors[Object.keys(chartColors)[i]],
          fill: false,
          pointRadius: 3,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: chartColors[Object.keys(chartColors)[i]],
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: chartColors.tooltipShadow,
        }

        chartDatesets.push(dataset)
      }

      const chartData = {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: 'label',
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                  maxTicksLimit: 15,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  //   stepSize: stepSizeY,
                  min: 0,
                  //   max: maxValueY,
                  callback: function(value) {
                    if (value % 1 === 0) {
                      return value
                    }
                  },
                  fontColor: chartColors.labelColor,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
              },
            ],
          },
          legend: {
            position: 'top',
            align: 'start',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
        data: {
          labels: data.labels,
          datasets: chartDatesets,
        },
      }

      return chartData
    },
  },
}
