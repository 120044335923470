<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        placeholder="Select Date Range"
        readonly
        v-bind="attrs"
        dense
        solo
        clearable
        hide-details
        :height="inputStyle.minHeight || inputStyle.height"
        :style="inputStyle"
        @click:clear="dates = []"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="dates"
      no-title
      :min="minDate"
      :max="new Date().toISOString().slice(0, 10)"
      first-day-of-week="1"
      show-adjacent-months
      range
      @input="$emit('input', dates)"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  props: {
    inputStyle: {
      type: Object,
      default: () => ({
        maxWidth: '250px',
      }),
    },
  },
  setup() {
    const menu = ref(false)
    const dates = ref([])
    const activePicker = ref(null)

    return {
      menu,
      activePicker,
      dates,
    }
  },
  computed: {
    minDate() {
      if (this.dates.length === 1) return this.dates[0]

      return '2022-01-01'
    },
    dateRangeText: {
      get() {
        if (this.dates != null && this.dates.length > 0) return this.dates.join(' ~ ')

        return []
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    dates() {
      this.$emit('input', this.dates)
    },
  },
}
</script>
